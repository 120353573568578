<div class="popup" [@fadeInOut]="showPopup() ? 'visible' : 'hidden'">
  <div class="header">
    <app-ditch-ads *ngIf="!ad?.isPartnerEventAd" (getPremiumClick)="openPremium()" />

    <button uiIconButton tertiary size="sm" data-cy="ad-popup-close-button" (click)="hide('ad_popup_close')">
      <i class="app-icon-close"></i>
    </button>
  </div>

  <app-enigma [ad]="ad" [location]="location" (isMediaLoaded)="onMediaLoaded()" />
</div>
